@font-face{
    font-family: 'Fira Code';
    src: url('./FiraCode-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.App {
  text-align: center;
  color: #f2f2f2;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
  padding: 10px;
}

.container > div {
  width: 100%;
}

.App-personage {
  max-width: 100%;
  max-height: 600px;
  display: block;
  margin: 0 auto;
}

.App-personage.thinking {
  margin: 2em auto;
  display: none;
}

.App-personage.sitting {
  max-width: 220px;
  margin: 1em auto;
}

.App-header h2 {
  font-weight: normal;
}

.App-introcode {
  max-width: 400px;
  margin: 0 auto;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px !important;
}

.App-intro {
  font-size: large;
  padding: 0 40px;
}

@media (min-width: 768px) {
  .container > div:first-child {
    width: 40%;
  }

  .container > div:last-child {
    order: 2;
    width: 60%;
  }

  .App-personage.thinking {
    display: block;
  }

  .App-personage.sitting {
    display: none;
  }
}
